import './App.css';
import Image from 'react-bootstrap/Image';
import Accordion from 'react-bootstrap/Accordion';

function App() {
  return (
    <div className="App">
      <div>
        <h1>현재 공사 중입니다</h1>
        <Image
          src={require('./assets/constructing.jpg')}
          alt="공사 중 이미지"
          width={300}
          height={300}
        />
        <p>다음 세대를 향한 하나님의 마음</p>
        <h3>다음교회</h3>
      </div>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>인삿말</Accordion.Header>
          <Accordion.Body>
            <Image src={require('./assets/greeting_card.png')} alt="인삿말" />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>오시는 길</Accordion.Header>
          <Accordion.Body>
            <h5>경기 부천시 소사구 성주로68번길 32</h5>
            <iframe
              width={1000}
              height={800}
              src="https://kko.to/nlInMSlY9F"
            ></iframe>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default App;
